import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { ThemeProvider } from "styled-components"
import locales from "../i18n/constants"
import { setLanguage } from "../redux/actions/languageActions"
import Footer from "./footer"
import Header from "./header"
import Sidebar from "./sidebar"
import Theme from "./theme"

/**
 *
 * Layout wrapper.
 * Assambles the headbar, footer, mainview and navigation
 *
 * @param {Array} children child objects passed into component
 * @param {Boolean} showCountry Show selected country
 * @param {Object} language redux object for language state
 * @param {Function} setLanguage redux function for choosing language
 * @param {String} keywords Translation
 * @param {Object} footer Translation
 * @param {Object} navigation react object for handling navigation
 * @param {Boolean} hidePicker hide language picker
 */
const Layout = ({
  showCountry = true,
  children,
  language,
  setLanguage,
  keywords,
  footer,
  navigation,
  hidePicker,
}) => {
  const { country } = language

  useEffect(() => {
    // If user enters page without any country set
    if (!country && window && window.location && window.location.pathname) {
      const route = window.location.pathname.split("/").filter(s => !!s)[0]
      if (!!locales[route]) {
        setLanguage(locales[route])
      }
    }
  }, [country, setLanguage])

  return (
    <Theme>
      <>
        <ThemeProvider theme={{ mode: "dark" }}>
          <Header
            showCountry={showCountry}
            hidePicker={hidePicker}
            country={country}
            keywords={keywords}
          />
          {navigation}
          <Sidebar keywords={keywords} />
        </ThemeProvider>
        {children}
        <Footer footer={footer} />
      </>
    </Theme>
  )
}
Layout.propTypes = {
  showCountry: PropTypes.bool,
  language: PropTypes.object.isRequired,
  setLanguage: PropTypes.func,
  keywords: PropTypes.object.isRequired,
  footer: PropTypes.object.isRequired,
  navigation: PropTypes.object,
  hidePicker: PropTypes.bool,
}

const mapStateToProps = state => {
  return { language: state.language }
}

const mapDispatchToProps = { setLanguage }

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
