import React from "react"
import { Elements } from "prismic-reactjs"
import styled from "styled-components"
import { containerText } from "../style/theme"

const H1 = styled.h1`
  font-family: Poppins;
  font-size: 1.5rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: left;
  color: ${containerText};
  margin: 0 0 0.625rem 0;
`

const H2 = styled.h2`
  font-family: Poppins;
  font-size: 1.25rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: ${containerText};
  margin: 1.25rem 0;
`

const P = styled.p`
  font-family: Lato;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: ${containerText};

  strong {
    font-weight: bold;
  }

  a:hover,
  a:visited,
  a:link,
  a:active {
    color: ${containerText};
    font-weight: bold;
  }
`

const Strong = styled.strong`
  font-family: Lato;
  font-size: 1rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: norma;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: ${containerText};
`

const OL = styled.ol`
  font-family: Lato;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: ${containerText};
`

const LI = styled.li`
  font-family: Lato;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: ${containerText};
`

const Img = styled.img`
  width: 65%;
  margin: 2rem auto;
  display: block;

  @media (max-width: 992px) {
    width: 100%;
  }
`
/**
 * @param {string} props
 * @param {string} key
 */
const propsWithUniqueKey = function (props, key) {
  return Object.assign(props || {}, { key })
}

/**
 *
 * Used with richtext to set same format on e.g textareas in CMS
 *
 * @param {string} type
 * @param {string} element
 * @param {string} content
 * @param {string} children
 * @param {string} key
 */
export const PagehtmlSerializer = function (
  type,
  element,
  content,
  children,
  key
) {
  switch (type) {
    case Elements.heading1: // Heading 1
      return <H1 key={element.text}>{element.text}</H1>

    case Elements.heading2: // Heading 2
      return <H2 key={element.text}>{element.text}</H2>

    case Elements.paragraph: // Paragraph
      return <P key={element.text}>{children}</P>

    case Elements.span: // Span
      if (content) {
        return content.split("\n").reduce((acc, p) => {
          if (acc.length === 0) {
            return [p]
          } else {
            const brIndex = (acc.length + 1) / 2 - 1
            const br = React.createElement(
              "br",
              propsWithUniqueKey({}, brIndex)
            )
            return acc.concat([br, p])
          }
        }, [])
      } else {
        return null
      }

    case Elements.strong: // Strong
      if (!children[0]) return null
      return <Strong key={children[0].key}>{children}</Strong>

    case Elements.oList: // Paragraph
      return <OL key={element.text}>{children}</OL>

    case Elements.oListItem: // Paragraph
      return <LI key={element.text}>{element.text}</LI>

    case Elements.image: // Image
      return <Img key={key} src={element.url} alt={element.alt} />

    case Elements.hyperlink: // Image
      return (
        <a
          key={key}
          href={element.data.url}
          target={"_blank"}
          rel={"noreferrer"}
        >
          {children}
        </a>
      )

    default:
      return null
  }
}
