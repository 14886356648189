/**
 * Object mapping against prismic and all possible languages in the CMS.
 * If one needs to add more languages, this needs to be updated.
 * This file is used in gatsby-node for creating pages
 */
module.exports = {
  au: {
    path: "en-au",
    locale: "en-au",
    country: "Australia",
    enable: false,
  },
  ca: {
    path: "en-ca",
    locale: "en-ca",
    country: "Canada",
    enable: true,
  },
  uk: {
    path: "en-gb",
    locale: "en-gb",
    country: "United Kingdom",
    enable: false,
  },
  nz: {
    path: "en-nz",
    locale: "en-nz",
    country: "New Zealand",
    enable: false,
  },
  us: {
    path: "en-us",
    locale: "en-us",
    country: "United States",
    enable: false,
  },
  se: {
    path: "sv-se",
    locale: "sv-se",
    country: "Sverige",
    enable: false,
  },
}
