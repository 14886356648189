import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import { urlForCountry } from "../helpers/routing"
import { setLanguage } from "../redux/actions/languageActions"
import { setOpen } from "../redux/actions/menuActions"
import { containerBackground, containerText } from "../style/theme"
import LanguagePicker from "./language-picker"
import logo from "../images/logo-yellow.png"

const HeaderContainer = styled.header`
  background: ${containerBackground};
  width: 100%;
  height: 10vh;
  min-height: 95px;
  @media (max-width: 900px) {
    min-height: 65px;
  }
`

const UIHeader = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 2rem;
`

const HeaderInner = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1920px;
  display: flex;
  justify-items: center;
  margin: 0 auto;
`

const LogoContainer = styled.div`
  height: 100%;
  display: flex;
  place-items: center;
  margin-right: auto;
`

const Nav = styled.nav`
  display: flex;
  place-items: center;
`

const IMG = styled.img`
  width: 250px;
  margin: auto;
  display: block;

  @media (max-width: 1450px) {
    width: 17vw;
  }
  @media (max-width: 1024px) {
    width: 20vw;
  }
  @media (max-width: 992px) {
    width: 25vw;
  }
  @media (max-width: 992px) {
    width: 25vw;
  }
  @media (max-width: 648px) {
    width: 30vw;
  }
  @media (max-width: 400px) {
    width: 35vw;
  }
`

const Country = styled.div`
  font-family: Poppins;
  font-size: 0.75rem;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: ${containerText};
  text-transform: capitalize;
  position: absolute;
`

const HeaderContentDesktop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  height: 100%;
  @media (max-width: 992px) {
    display: none;
  }
`

const HeaderContentMobile = styled.div`
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  @media (max-width: 992px) {
    display: flex;
  }
`

const HeaderLink = styled(Link)`
  margin: 0 0.5rem;
  font-family: Lato;
  text-decoration: none;
  color: ${containerText};

  :first-letter {
    text-transform: uppercase;
  }

  @media (min-width: 991px) {
    display: none;
  }
`

const TitleLink = styled(Link)`
  text-decoration: none;
  color: ${containerText};
  position: absolute;
  font-family: Poppins;
  font-size: 1.75rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  left: 0;

  position: relative;
`

export const StyledBurger = styled.button`
  opacity: 1;
  top: 0.5rem;
  right: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 100;
  margin: 0 1rem;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: ${containerText};
    border-radius: 10px;
    transition: all 300ms linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`

const LanguageSelection = styled.div`
  margin: 0 2rem;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: flex-end;
  justify-content: center;

  @media (max-width: 992px) {
    display: none;
  }
`

/**
 *
 * Shows header.
 * Used by most components with few alterations in the index view
 *
 * @param {Object} language redux object for language settings
 * @param {Function} setOpen redux function for open menu
 * @param {Object} menu redux object for keeping track of menu state
 * @param {Object} keywords Translations
 * @param {Boolean} hidePicker set to true to remove language selection, only used in index
 * @param {Boolean} showCountry hides language flag, only used in index
 */
const Header = ({
  language,
  setOpen,
  menu,
  keywords,
  hidePicker,
  showCountry,
}) => {
  const { path } = language
  const { isOpen } = menu
  const { glossary, news, aboutus } = keywords.edges[0].node

  hidePicker = true //Add this back for language support

  return (
    <HeaderContainer>
      <HeaderContentDesktop>
        <UIHeader>
          <HeaderInner>
            <LogoContainer>
              <TitleLink to={urlForCountry(path, "start")}>
                <IMG
                  src={logo}
                  alt="esportleet logo with name"
                  loading="lazy"
                />
                {showCountry && language && language.country ? (
                  <Country>{language.country}</Country>
                ) : null}
              </TitleLink>
            </LogoContainer>
          </HeaderInner>
        </UIHeader>

        <HeaderLink to={urlForCountry(path, "glossary")}>
          {glossary[0].text}
        </HeaderLink>
        <HeaderLink to={urlForCountry(path, "news")}>{news[0].text}</HeaderLink>
        <HeaderLink to={urlForCountry(path, "aboutus")}>
          {aboutus[0].text}
        </HeaderLink>
        {hidePicker ? null : (
          <LanguageSelection>
            <LanguagePicker />
          </LanguageSelection>
        )}
      </HeaderContentDesktop>

      <HeaderContentMobile>
        <UIHeader>
          <HeaderInner>
            <LogoContainer>
              <TitleLink to={urlForCountry(path, "start")}>
                <IMG
                  src={logo}
                  alt="esportleet logo with name"
                  loading="lazy"
                />
              </TitleLink>
            </LogoContainer>
            <Nav>
              <StyledBurger
                open={isOpen}
                onClick={e => setOpen(!isOpen)}
                aria-label="hamburger menu"
              >
                <div />
                <div />
                <div />
              </StyledBurger>
            </Nav>
          </HeaderInner>
        </UIHeader>
      </HeaderContentMobile>
    </HeaderContainer>
  )
}

Header.propTypes = {
  language: PropTypes.object,
  setOpen: PropTypes.func.isRequired,
  menu: PropTypes.object.isRequired,
  keywords: PropTypes.object.isRequired,
  hidePicker: PropTypes.bool,
  showCountry: PropTypes.bool.isRequired,
}

const mapStateToProps = state => {
  return { language: state.language, menu: state.menu }
}

const mapDispatchToProps = { setLanguage, setOpen }

export default connect(mapStateToProps, mapDispatchToProps)(Header)
