import React from "react"
import { ThemeProvider, createGlobalStyle } from "styled-components"
import { primaryTextColor, bodyBackground } from "../style/theme"
import { connect } from "react-redux"
import PropTypes from "prop-types"

/**
 * Context and provider for handling the theme context
 */
const { Consumer, Provider } = React.createContext("theme")

// const THEME_LS_KEY = "theme_key"

// const supportsDarkMode = () =>
//   window.matchMedia("(prefers-color-scheme: dark)").matches === true

const GlobalStyle = createGlobalStyle`
  html,body {
      margin: 0;
      scroll-behavior: smooth;
      background: ${bodyBackground};
      font-size: calc(12px + (16 - 12) * ((100vw - 300px) / (1920 - 300)));
      line-height: calc(12px + (16 - 12) * ((100vw - 300px) / (1920 - 300)));
      color: ${primaryTextColor};
      font-family: "Lato Anton";
  }

  @media (min-width: 1920px) {
    html,body {
      line-height: 18px; 
      line-height: 18px; 
    }
  }

  @media (min-width: 2560px) {
    html,body {
      font-size: 18px;
      line-height: 18px; 
    }
  }
  
  @media (min-width: 3840px) {
    html,body {
      font-size: 38px;
      line-height: 38px; 
    }
  }
  

  input {
    filter: none;
  }
  
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
  :-moz-autofill-preview {
    filter: grayscale(21%) brightness(88%) contrast(161%) invert(10%) sepia(40%) saturate(206%);
  }
`

/**
 *
 * Sets the theme context for the application.
 *
 * TODO: Dark theme?
 *
 * @param {Object} children react child objects
 * @param {string} theme string for color scheme
 */
const Theme = ({ children, theme }) => {
  return (
    <ThemeProvider theme={{ mode: theme }}>
      <>
        <GlobalStyle />
        {children}
      </>
    </ThemeProvider>
  )
}
Theme.propTypes = {
  children: PropTypes.object.isRequired,
  theme: PropTypes.string.isRequired,
}

Theme.Consumer = Consumer
Theme.Provider = Provider

const mapStateToProps = state => {
  return { theme: state.theme }
}

export default connect(mapStateToProps, null)(Theme)
