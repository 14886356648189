import { useLocation } from "@reach/router"
import { navigate } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import locales from "../i18n/constants"
import au from "../images/flags/australia-flag-round.svg"
import ca from "../images/flags/canada-flag-round.svg"
import nz from "../images/flags/new-zealand-flag-round.svg"
import se from "../images/flags/sweden-flag-round.svg"
import uk from "../images/flags/united-kingdom-flag-round.svg"
import us from "../images/flags/united-states-of-america-flag-round.svg"
import { setLanguage } from "../redux/actions/languageActions"
import { setOpen } from "../redux/actions/menuActions"

/**
 * Maps against flags name
 */
const flags = {
  // au,
  ca,
  // nz,
  // uk,
  // us,
  // se,
}

/**
 * Animation delay between images
 */
const staggerDelay = 100

const IMG = styled.img`
  width: 3vw;
  align-self: center;
  cursor: pointer;
  margin: 0 0.2rem;
  transition: 400ms;
  transform: ${props => `translateX(${props.length})`};
  z-index: ${props => (props.selected ? 1 : 0)};
  position: absolute;
  transition: ${props => `200ms ${props.delay} ease-in`};

  @media (max-width: 992px) {
    width: 8vw;
  }
  @media (max-width: 1024px) {
    width: 8vw;
    right: 0;
  }
`

/**
 *
 * Animated country picker
 * In desktop view its located in the header
 * In mobile view its located in the drawer
 *
 * @param {Object} language redux object for language state
 * @param {Function} setLanguage redux function used to activte language
 * @param {Function} setOpen redux function used to set state on menu
 * @param {Object} menu redux object for handling menu
 */
const LanguagePicker = ({ language, setLanguage, setOpen, menu }) => {
  const [showSelection, setShowSelection] = useState(false)
  const location = useLocation()

  const sortLocales = loc => {
    if (language && language.path) {
      let l = Object.values(loc)
      l.push(l.splice(l.indexOf(locales[language.path]), 1)[0])
      return l.reverse()
    }
    return []
  }

  const handleClick = event => {
    const country = event.target.id
    setShowSelection(!showSelection)

    if (showSelection) {
      setTimeout(() => {
        setLanguage(locales[country])
        let path = location.pathname.split("/")
        path[1] = country
        navigate(`${path.join("/")}`)

        if (menu.isOpen) {
          setOpen(false)
        }
      }, staggerDelay * 1.2 * Object.keys(flags).length)
    }
  }

  if (!language || !language.path) return null
  return (
    <>
      {sortLocales(locales)
        .filter(l => l.enable === true) // TODO: Remove this line and add back countries in the flag-list to enable them
        .map((l, i) => (
          <IMG
            key={`locales-${i}`}
            length={showSelection ? (i * -120).toString() + "%" : 0}
            selected={l.path === language.path}
            delay={(staggerDelay * i).toString() + "ms"}
            id={l.path}
            onClick={handleClick}
            src={flags[l.path]}
            alt={`${l.country} flag`}
          />
        ))}
    </>
  )
}

LanguagePicker.propTypes = {
  language: PropTypes.object.isRequired,
  setLanguage: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  menu: PropTypes.object.isRequired,
}

const mapStateToProps = state => {
  return { language: state.language, menu: state.menu }
}

const mapDispatchToProps = { setLanguage, setOpen }

export default connect(mapStateToProps, mapDispatchToProps)(LanguagePicker)
