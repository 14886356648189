import { faHome } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import { navigateToUrlForCountry } from "../helpers/routing"
import { setOpen } from "../redux/actions/menuActions"
import {
  accentContainerText,
  containerBackground,
  containerText,
  primaryOrange,
} from "../style/theme"
// import LanguagePicker from "./language-picker"
import PropTypes from "prop-types"

const Container = styled.div`
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: 0.5s;
  text-align: center;
  background: ${containerBackground};
  height: 100%;
  transition: 300ms ease-in;
  width: ${props => (props.isOpen ? "100%" : "0%")};

  @media (min-width: 993px) {
    display: none;
  }
`

const AnimationBackgrounds = styled.div`
  position: absolute;
  display: block;
  width: 200%;
  background: ${primaryOrange};
  transform: rotate(-45deg) translateY(-152%);
  transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
  transition-delay: 300ms;
  transform: rotate(-45deg) scaleY(1);
  height: 40vh;

  ${({ isOpen }) =>
    isOpen &&
    `
    transform: rotate(-45deg) scaleY(0)
  `};
`

const BGtop = styled(AnimationBackgrounds)`
  left: -75%;
  top: -6%;
`

const BGmid = styled(AnimationBackgrounds)`
  top: 29%;
  left: -53%;
`

const BGbot = styled(AnimationBackgrounds)`
  top: 63%;
  left: -20%;
`

const Menu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
`

const Home = styled.div`
  font-size: 2rem;
  color: ${accentContainerText};
  background: ${primaryOrange};
  padding: 1.3rem;
  border-radius: 3rem;
`

const MenuItem = styled.span`
  font-family: Lato;
  text-decoration: none;
  color: ${containerText};
  font-size: 1.6rem;
  margin: 1rem auto;
  opacity: ${props => (props.isOpen ? 1 : 0)};
  transition: ${props => `200ms ${props.delay} ease-in`};
  :first-letter {
    text-transform: uppercase;
  }
`

const Hr = styled.hr`
  width: 0%;
  width: ${props => (props.isOpen ? "80%" : "0%")};
  transition: ${props => `200ms ${props.delay} ease-in`};
`

// const LanguageSelection = styled.div`
//   position: relative;
//   bottom: -12vh;
//   right: -33vw;
//   opacity: ${props => (props.isOpen ? 1 : 0)};
//   transition: ${props => `200ms ${props.delay} ease-in`};
// `

/**
 *
 * Drawer navigation
 * Only shown on smaller screens
 *
 * @param {Function} setOpen redux function for handling state of menu
 * @param {Object} menu redux object for menu state
 * @param {Object} language redux object for language state
 * @param {Object} keywords Translation
 */
const Sidebar = ({ language, menu, setOpen, keywords }) => {
  const handleNavigation = url => {
    const { path } = language
    setOpen(false)
    navigateToUrlForCountry(path, url)
  }

  if (!keywords) return null

  const { isOpen } = menu
  const { glossary, news, aboutus } = keywords.edges[0].node

  return (
    <Container isOpen={isOpen}>
      <BGmid isOpen={isOpen} />
      <BGtop isOpen={isOpen} />
      <BGbot isOpen={isOpen} />

      <Menu>
        <MenuItem
          delay={"0.6s"}
          isOpen={isOpen}
          onClick={e => handleNavigation("start")}
        >
          <Home isOpen={isOpen}>
            <FontAwesomeIcon icon={faHome} />
          </Home>
        </MenuItem>
        <Hr delay={"1.2s"} isOpen={isOpen} />
        {/* <MenuItem
          delay={"0.7s"}
          isOpen={isOpen}
          onClick={e => handleNavigation("news")}
        >
          {knowledge[0].text}
        </MenuItem>
        <MenuItem
          delay={"0.8s"}
          isOpen={isOpen}
          onClick={e => handleNavigation("strategy")}
        > 
          {strategies[0].text}
        </MenuItem>*/}
        <MenuItem
          delay={"0.9s"}
          isOpen={isOpen}
          onClick={e => handleNavigation("dictionary")}
        >
          {glossary[0].text}
        </MenuItem>
        <MenuItem
          delay={"1s"}
          isOpen={isOpen}
          onClick={e => handleNavigation("news")}
        >
          {news[0].text}
        </MenuItem>
        <MenuItem
          delay={"1.1s"}
          isOpen={isOpen}
          onClick={e => handleNavigation("about-us")}
        >
          {aboutus[0].text}
        </MenuItem>
        {/* <LanguageSelection delay={"1.3s"} isOpen={isOpen}>
          <LanguagePicker />
        </LanguageSelection> */}
      </Menu>
    </Container>
  )
}
Sidebar.propTypes = {
  setOpen: PropTypes.func.isRequired,
  menu: PropTypes.object.isRequired,
  language: PropTypes.object.isRequired,
  keywords: PropTypes.object.isRequired,
}
const mapStateToProps = state => {
  return { language: state.language, menu: state.menu }
}

const mapDispatchToProps = { setOpen }

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
