/**
 *
 * Format data according to locale
 *
 * @param {string} locale
 * @param {string} date
 */
export const formatFullYear = (locale, date) => {
  return new Intl.DateTimeFormat(locale).format(new Date(date.slice(0, 16)))
}

export const getCurrentYear = () => new Date().getFullYear()
