import theme from "styled-theming"

// TODO slim down

export const NavigationButton = theme("mode", {
  light: "#fafafa",
  dark: "#fafafa",
})

export const NavigationButtonTextColor = theme("mode", {
  light: "#1e1e1e",
  dark: "#1e1e1e",
})

export const NavigationButtonHover = theme("mode", {
  light: "#f95759",
  dark: "#f95759",
})

export const NavigationButtonHoverTextColor = theme("mode", {
  light: "#f5f5f5",
  dark: "#f5f5f5",
})

export const TableGray = theme("mode", {
  light: "#f0f0f0",
  dark: "#f0f0f0",
})

export const bodyBackground = theme("mode", {
  light: "#FAFAFA",
  dark: "#FAFAFA",
})

export const containerBackground = theme("mode", {
  light: "#FAFAFA",
  dark: "#1e1e1e",
})

export const containerText = theme("mode", {
  light: "#1e1e1e",
  dark: "#fafafa",
})

export const invertedContainerBackground = theme("mode", {
  light: "#1e1e1e",
  dark: "#1e1e1e",
})

export const invertedContainerText = theme("mode", {
  light: "#fafafa",
  dark: "#fafafa",
})

export const accentContainer = theme("mode", {
  light: "#f95759",
  dark: "#f95759",
})

export const accentContainerText = theme("mode", {
  light: "#323232",
  dark: "#323232",
})

export const disabledColor = theme("mode", {
  light: "#c4c4c4",
  dark: "#c4c4c4",
})

export const primaryTextColor = theme("mode", {
  light: "#17252B",
  dark: "#315261",
})

export const accentTextColor = theme("mode", {
  light: "#F5F5F5",
  dark: "#F5F5F5",
})

export const neonBlue = theme("mode", {
  light: "#00ebd5",
  dark: "#00ebd5",
})

export const darkNeonBlue = theme("mode", {
  light: "#21b2a6",
  dark: "#21b2a6",
})

export const primaryTitleColor = theme("mode", {
  light: "#fec938",
  dark: "#fec938",
})

export const primaryOrange = theme("mode", {
  light: "#fec938",
  dark: "#fec938",
})
export const primaryDarkText = theme("mode", {
  light: "#303030",
  dark: "#303030",
})

export const footerBackgroundColor = theme("mode", {
  light: "#151515",
  dark: "#151515",
})
