import { navigate } from "gatsby"

/**
 *
 * Adds country to the url
 *
 * @param {string} country
 * @param {string} url
 */
export const navigateToUrlForCountry = (country, url) =>
  navigate(`/${country}/${url.toLowerCase()}`)
export const urlForCountry = (country, url) => `/${country}/${url}`
