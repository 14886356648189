import { Link } from "gatsby"
import React from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import { getCurrentYear } from "../helpers/dates"
import { urlForCountry } from "../helpers/routing"
import logo from "../images/cpriofav-yellow.png"
import {
  footerBackgroundColor,
  invertedContainerText,
  primaryOrange,
} from "../style/theme"
import PropTypes from "prop-types"
import { RichText } from "prismic-reactjs"
import { PagehtmlSerializer } from "../utils/pageHtmlSerializer"

// import twitter from "../images/icons/Twitter_Logo_WhiteOnImage@3x.png"
// import facebook from "../images/icons/f_logo_RGB-Black_58@3x.png"
// TODO add these back

const FooterContainer = styled.div`
  width: 100%;
  min-height: 30vh;
  height: max-content;
  padding: 2rem 0;
  background-color: ${footerBackgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
`
const FooterContainerInner = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  max-width: 1920px;
  display: grid;
  row-gap: 1rem;
  column-gap: 55px;
  margin: 0 2rem;

  justify-content: center;
  grid-template-rows: repeat(auto-fill, auto);
  grid-template-columns: repeat(auto-fill, auto);
  grid-template-areas:
    "logo . . about resources legal"
    "brand . .  contact whatwedo responsiblegaming"
    "bottomlinks bottomlinks bottomlinks bottomlinks bottomlinks bottomlinks";
  @media (max-width: 892px) {
    grid-template-areas:
      "logo . about resources legal"
      "brand .  contact whatwedo responsiblegaming"
      "bottomlinks bottomlinks bottomlinks bottomlinks bottomlinks";
  }

  @media (max-width: 786px) {
    grid-template-areas:
      "logo logo logo"
      "brand brand brand"
      "about resources legal"
      "contact contact contact"
      "whatwedo whatwedo whatwedo"
      "responsiblegaming responsiblegaming responsiblegaming"
      "bottomlinks bottomlinks bottomlinks";
  }
  @media (max-width: 580px) {
    grid-template-areas:
      "logo logo logo"
      "brand brand brand"
      "about . resources"
      "legal legal legal"
      "contact contact contact"
      "whatwedo whatwedo whatwedo"
      "responsiblegaming responsiblegaming responsiblegaming"
      "bottomlinks bottomlinks bottomlinks";
  }
`
const Logo = styled.div`
  grid-area: logo;
  display: flex;
  align-items: center;

  @media (max-width: 786px) {
    justify-content: center;
  }
`
const About = styled.div`
  grid-area: about;
  white-space: nowrap;
`
const Resources = styled.div`
  grid-area: resources;
`
const Legal = styled.div`
  grid-area: legal;
`
const Brand = styled.div`
  grid-area: brand;
  display: flex;
  flex-direction: column;

  @media (max-width: 786px) {
    margin: 0 auto;
    align-items: center;
  }
`

const Contact = styled.div`
  grid-area: contact;
`
const WhatWeDo = styled.div`
  grid-area: whatwedo;
`
const ResponsibleGaming = styled.div`
  grid-area: responsiblegaming;
`
const BottomLinks = styled.div`
  grid-area: bottomlinks;

  & p {
    font-family: Verdana;
    font-size: 9px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: right;
    color: ${invertedContainerText} !important;
    text-decoration: none !important;
  }

  & a {
    font-family: Verdana;
    font-size: 9px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: right;
    color: ${invertedContainerText} !important;
    text-decoration: none !important;
  }
`

const LogoImgContainer = styled.div`
  width: 15%;
  min-width: 75px;
  @media (max-width: 786px) {
    width: 10%;
  }
`
const LogoImg = styled.img`
  width: 100%;
`
const CategoryTitle = styled.h1`
  font-size: 16px;
  width: max-content;
  font-family: Poppins;
  line-height: 20px;
  font-weight: 600;
  color: ${invertedContainerText};
  position: relative;
  -webkit-font-smoothing: subpixel-antialiased;

  &:after {
    content: "";
    width: 100%;
    border-bottom: 1px solid ${primaryOrange};
    position: absolute;
    bottom: -50%;
    left: 0;
    @media (max-width: 786px) {
      bottom: -35%;
    }
  }
`
const CategoryList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  font-family: Lato;
  font-size: 14px;
  margin: 0;
  padding: 0;
`
const CategoryListItem = styled.li`
  display: flex;
  align-items: center;
  padding: 0.25rem 0;
  color: ${invertedContainerText};
`

const CLIa = styled(Link)`
  color: inherit;
  text-decoration: none;
  width: 100%;
`

const CategoryBreadText = styled.p`
  color: #919191; /* Switch to theme color */
  font-family: Lato;
  font-size: 14px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  line-height: 1.35rem;
`

const RightsReserved = styled(CategoryBreadText)`
  @media (max-width: 1124px) {
    font-size: 1.19vw;
  }
  @media (max-width: 786px) {
    font-size: 1.75vw;
  }
  @media (max-width: 586px) {
    font-size: 0.85rem;
  }
`

/**
 *
 * Shows the footer component
 *
 * TODO show images for social network.
 *
 * @param {Object} language redux object for language choice
 * @param {Object} footer Translations
 */
const Footer = ({ language, footer }) => {
  const { path } = language
  const {
    about,
    aboutus,
    resources,
    news,
    dictionary,
    legal,
    disclaimer,
    privacypolicy,
    whatwedo,
    whatwedotext,
    responsiblegaming,
    responsiblegamingtext,
    contact,
    contacttext,
    bottomlinks,
  } = footer.edges[0].node

  return (
    <FooterContainer>
      <FooterContainerInner>
        <Logo>
          <LogoImgContainer>
            <LogoImg src={logo} alt="esportleet logo" loading="lazy" />
          </LogoImgContainer>
        </Logo>
        <About>
          <CategoryTitle>{about[0].text}</CategoryTitle>
          <CategoryList>
            <CategoryListItem>
              <CLIa to={urlForCountry(path, "about-us")}>
                {aboutus[0].text}
              </CLIa>
            </CategoryListItem>
          </CategoryList>
        </About>
        <Resources>
          <CategoryTitle>{resources[0].text}</CategoryTitle>
          <CategoryList>
            <CategoryListItem>
              <CLIa to={urlForCountry(path, "news")}>{news[0].text}</CLIa>
            </CategoryListItem>
            <CategoryListItem>
              <CLIa to={urlForCountry(path, "dictionary")}>
                {dictionary[0].text}
              </CLIa>
            </CategoryListItem>
          </CategoryList>
        </Resources>
        <Legal>
          <CategoryTitle>{legal[0].text}</CategoryTitle>
          <CategoryList>
            <CategoryListItem>
              <CLIa to={urlForCountry(path, "disclaimer")}>
                {disclaimer[0].text}
              </CLIa>
            </CategoryListItem>
            <CategoryListItem>
              <CLIa to={urlForCountry(path, "privacy-policy")}>
                {privacypolicy[0].text}
              </CLIa>
            </CategoryListItem>
          </CategoryList>
        </Legal>
        <Brand>
          <CategoryTitle>Compare. Select. Play</CategoryTitle>
          <RightsReserved>
            © {getCurrentYear()} EsportLeet. All Rights Reserved
          </RightsReserved>
          <a
            href="//www.dmca.com/Protection/Status.aspx?ID=c782e28d-77e1-4b0e-afe4-5d4c13b2fcb3"
            title="DMCA.com Protection Status"
            className="dmca-badge"
          >
            {" "}
            <img
              src="https://images.dmca.com/Badges/dmca-badge-w100-5x1-01.png?ID=c782e28d-77e1-4b0e-afe4-5d4c13b2fcb3"
              alt="DMCA.com Protection Status"
            />
          </a>{" "}
          <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js%22%3E" />
        </Brand>
        <Contact>
          <CategoryTitle>{contact[0].text}</CategoryTitle>
          <CategoryBreadText>{contacttext[0].text}</CategoryBreadText>
        </Contact>
        <WhatWeDo>
          <CategoryTitle>{whatwedo[0].text}</CategoryTitle>
          <CategoryBreadText>{whatwedotext[0].text}</CategoryBreadText>
        </WhatWeDo>
        <ResponsibleGaming>
          <CategoryTitle>{responsiblegaming[0].text}</CategoryTitle>
          <CategoryBreadText>{responsiblegamingtext[0].text}</CategoryBreadText>
        </ResponsibleGaming>
        <BottomLinks>
          <RichText render={bottomlinks} htmlSerializer={PagehtmlSerializer} />
        </BottomLinks>
      </FooterContainerInner>
    </FooterContainer>
  )
}
Footer.propTypes = {
  language: PropTypes.object.isRequired,
  footer: PropTypes.object.isRequired,
}

const mapStateToProps = state => {
  return { language: state.language }
}

export default connect(mapStateToProps, null)(Footer)
