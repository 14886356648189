import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import SchemaOrg from "./schema-org"

/**
 *
 * General SEO tags
 * Overwrite basic settings in the siteMetadata by sending in new values with object meta
 *
 * TODO investigate if tags is missing
 *
 * @param {Object} meta object containing data from parent page
 * @param {Boolean} isPost if true, more metadata will be active. used by articles
 */
function SEO({ meta = {}, isPost }) {
  const siteMetadata = {
    title: `EsportLeet`,
    description: `All you need in online gaming`,
    siteUrl: `https://www.esportLeet.com`,
    keywords: `Online Casino, Esports Betting, Sports Betting, Mobile Casino, Campaigns, Knowledge, Strategies, News`,
    img: `/images/main.jpg`,
    author: {
      name: "Raven",
    },
    organization: {
      name: "EsportLeet.",
      url: "https://www.esportleet.com",
      logo: "https://www.esportleet.com/images/main.jpg",
    },
  }

  const _title = meta.title || siteMetadata.title

  const _description = meta.description || siteMetadata.description

  const _img =
    !!meta && !!meta.img
      ? meta.img
      : `${siteMetadata.siteUrl}${siteMetadata.img}`

  const _url =
    !!meta && !!meta.url
      ? siteMetadata.siteUrl + meta.url
      : siteMetadata.siteUrl
  const _datePublished = meta && meta.creation_date ? meta.creation_date : null

  const _keywords =
    !!meta && !!meta.keywords ? meta.keywords : siteMetadata.keywords

  return (
    <React.Fragment>
      <Helmet>
        <link
          rel="alternate"
          href="https://www.esportLeet.com"
          hreflang="x-default"
        />
        <link
          rel="alternate"
          href="https://www.esportLeet.com/au/start"
          hreflang="en-au"
        />
        <link
          rel="alternate"
          href="https://www.esportLeet.com/ca/start"
          hreflang="en-ca"
        />
        <link
          rel="alternate"
          href="https://www.esportLeet.com/uk/start"
          hreflang="en-gb"
        />
        <link
          rel="alternate"
          href="https://www.esportLeet.com/nz/start"
          hreflang="en-nz"
        />
        <link
          rel="alternate"
          href="https://www.esportLeet.com/us/start"
          hreflang="en-us"
        />
        <link
          rel="alternate"
          href="https://www.esportLeet.com/sv/start"
          hreflang="sv-se"
        />

        {/* General tags */}
        <title>{_title}</title>
        <meta name="description" content={_description.replace(/\s/g, " ")} />
        <meta name="image" content={_img} />
        <meta name="keywords" content={_keywords.replace(/\s/g, " ")} />
        <link rel="canonical" href={_url} />

        {/* OpenGraph tags */}
        <meta property="og:url" content={_url} />
        {!!isPost ? <meta property="og:type" content="article" /> : null}
        <meta property="og:title" content={_title} />
        <meta property="og:description" content={_description} />
        <meta property="og:image" content={_img} />
        {/* <meta property="fb:app_id" content={seo.social.fbAppID} /> */}

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        {/* <meta name="twitter:creator" content={seo.social.twitter} /> */}
        <meta name="twitter:title" content={_title} />
        <meta name="twitter:description" content={_description} />
        <meta name="twitter:image" content={_img} />
      </Helmet>

      <SchemaOrg
        isPost={isPost}
        url={_url}
        title={_title}
        image={_img}
        description={_description}
        datePublished={_datePublished}
        siteUrl={siteMetadata.siteUrl}
        author={siteMetadata.author}
        organization={siteMetadata.organization}
        defaultTitle={siteMetadata.title}
      />
    </React.Fragment>
  )
}

SEO.propTypes = {
  meta: PropTypes.object,
  isPost: PropTypes.bool,
}

export default SEO
